import React, { useState, useEffect } from 'react';
import { auth } from './config/firebase';
import { User } from "firebase/auth";
import logo from './assets/_7afa50e7-4fe5-42b5-a782-8293c4c5172e-removebg-preview.png';
import logoText from './assets/logo_text.png'
import { Link } from 'react-router-dom';
import logoLato from './assets/logo_lato.png'
import { Dialog, Tab, DialogPanel } from '@headlessui/react';
import { Bars3Icon, XMarkIcon, MinusSmallIcon, PlusSmallIcon, PlusIcon } from '@heroicons/react/24/outline';



const navigation = [
    { name: 'How it works', href: '#' },
    { name: 'FAQ', href: '#' },
    { name: 'Blog', href: '#' },
    { name: 'About us', href: '#' },
];

const posts = [
    {
        id: 1,
        title: 'The advantages of hint-based interview prep',
        href: '#',
        description:
            'Investigating why  hint-based interview prep is a better way to prepare for technical interviews.',
        imageUrl:
            'https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3603&q=80',
        date: 'Sept 17, 2024',
        datetime: '2020-03-16',
        category: { title: 'Interview Prep', href: '#' },

    },
    // More posts...
]


const Blog = () => {
    const [user, setUser] = useState<User | null>(null);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((authUser) => {
            if (authUser) {
                setUser(authUser);
            } else {
                console.log("User is signed out");
                setUser(null);
            }
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {

        document.title = 'Terms of Service | HinterviewGPT';

    }, []);

    return (
        <div className="" style={{ backgroundColor: "#fbfafd" }}>
            <header className={`top-0 z-50 transition-all duration-300 border-b bg-[#f5f8fa]`}>
                <nav
                    aria-label="Global"
                    className="max-w-7xl mx-auto flex items-center justify-between py-4 px-2 lg:px-4"
                >
                    <div className="flex lg:flex-1">
                        <a href="/" className="-m-1.5 p-1.5">
                            <span className="sr-only">Your Company</span>
                            <img alt="" src={logoLato} className="h-8 w-auto" />
                        </a>
                    </div>
                    <div className="flex lg:hidden">
                        <button
                            type="button"
                            onClick={() => setMobileMenuOpen(true)}
                            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                        >
                            <span className="sr-only">Open main menu</span>
                            <Bars3Icon aria-hidden="true" className="h-6 w-6" />
                        </button>
                    </div>
                    <div className="hidden lg:flex lg:gap-x-12">
                        {navigation.map((item) => (
                            <a
                                key={item.name}
                                href={item.href}
                                className="text-sm font-semibold leading-6 text-gray-900"
                            >
                                {item.name}
                            </a>
                        ))}
                    </div>
                    <div className="hidden lg:flex lg:flex-1 lg:justify-end items-center">
                        {user ? (
                            <a
                                href="/activity"
                                className="text-sm font-semibold leading-6 text-gray-900"
                            >
                                App <span aria-hidden="true">&rarr;</span>
                            </a>
                        ) : (
                            <>
                                <a
                                    href="/auth?type=login"
                                    className="rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    Log In
                                </a>
                                <a
                                    href="/auth?type=register"
                                    className="ml-4 rounded-md border border-indigo-600 px-4 py-2 text-sm font-semibold text-indigo-600 hover:bg-indigo-600 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    Register
                                </a>
                            </>
                        )}
                    </div>
                </nav>

                {/* Mobile Menu */}
                <Dialog open={mobileMenuOpen} onClose={setMobileMenuOpen} className="lg:hidden">
                    <div className="fixed inset-0 z-50" />
                    <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                        <div className="flex items-center justify-between">
                            <a href="/" className="-m-1.5 p-1.5">
                                <span className="sr-only">Your Company</span>
                                <img
                                    alt=""
                                    src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                                    className="h-8 w-auto"
                                />
                            </a>
                            <button
                                type="button"
                                onClick={() => setMobileMenuOpen(false)}
                                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                            >
                                <span className="sr-only">Close menu</span>
                                <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                            </button>
                        </div>
                        <div className="mt-6 flow-root">
                            <div className="-my-6 divide-y divide-gray-500/10">
                                <div className="space-y-2 py-6">
                                    {navigation.map((item) => (
                                        <a
                                            key={item.name}
                                            href={item.href}
                                            className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                        >
                                            {item.name}
                                        </a>
                                    ))}
                                </div>
                                <div className="py-6">
                                    {user ? (
                                        <a
                                            href="/activity"
                                            className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                        >
                                            App
                                        </a>
                                    ) : (
                                        <>
                                            <a
                                                href="/auth?type=login"
                                                className="rounded-md bg-indigo-600 px-4 py-2 text-base font-semibold text-white hover:bg-indigo-500"
                                            >
                                                Log In
                                            </a>
                                            <a
                                                href="/auth?type=register"
                                                className="ml-4 rounded-md border border-indigo-600 px-4 py-2 text-base font-semibold text-indigo-600 hover:bg-indigo-600 hover:text-white"
                                            >
                                                Register
                                            </a>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </DialogPanel>
                </Dialog>
            </header>


            <main className="flex-grow p-4 lg:px-28 flex flex-col min-h-screen">
                <div className="py-24 sm:py-22">
                    <div className="mx-auto max-w-7xl px-6 lg:px-8">
                        <div className="mx-auto max-w-2xl text-center">
                            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                                From the HinterviewGPT blog
                            </h2>
                        </div>
                        <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                            {posts.map((post) => (
                                <article key={post.id} className="flex flex-col items-start justify-between">
                                    {/* <div className="relative w-full">
                                        <img
                                            alt=""
                                            src={post.imageUrl}
                                            className="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                                        />
                                    </div> */}
                                    <div className="max-w-xl border border-gray-200 p-8 rounded-xl">
                                        <div className="mt-2 flex items-center gap-x-4 text-xs">
                                            <time dateTime={post.datetime} className="text-gray-500">
                                                {post.date}
                                            </time>
                                        </div>
                                        <div className="group relative">
                                            <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                                                <Link to={`/blog/${post.id}`}>
                                                    {post.title}
                                                </Link>
                                            </h3>
                                            <p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">{post.description}</p>
                                        </div>
                                    </div>
                                </article>
                            ))}
                        </div>
                    </div>
                </div>
            </main>

            <footer className="bg-white border-t border-gray-200 mt-auto">
                <div className="mx-auto max-w-7xl py-3 px-4 sm:px-6 lg:px-8 flex justify-between items-center text-sm">
                    <p className="text-gray-500">© 2024 HinterviewGPT. All rights reserved.</p>
                    <div>
                        <a href="/privacy" className="text-indigo-600 hover:text-indigo-900 ml-4">
                            Privacy Policy
                        </a>
                        <a href="/tos" className="text-indigo-600 hover:text-indigo-900 ml-4">
                            Terms of Service
                        </a>
                    </div>
                </div>
            </footer>
        </div>

    );
};

export default Blog;
