import { useEffect, useState } from 'react';
import { auth, db } from './config/firebase';
import { User } from "firebase/auth";
import { collection, addDoc } from "firebase/firestore";
import Navbar from './navbar';
import { useNavigate } from 'react-router-dom';
import logo from './assets/_7afa50e7-4fe5-42b5-a782-8293c4c5172e-removebg-preview.png';
import { Bars3Icon, XMarkIcon, MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline';
import logoText from './assets/logo_text.png'
import logoLato from './assets/logo_lato.png'
import { Dialog, Tab, DialogPanel } from '@headlessui/react';



const navigation = [
    { name: 'How it works', href: '#' },
    { name: 'FAQ', href: '#' },
    { name: 'Blog', href: '#' },
    { name: 'About us', href: '#' },
];



const PrivacyPolicy = () => {

    const [expanded, setExpanded] = useState<string | false>('');
    const [user, setUser] = useState<User | null>(null);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((authUser) => {
            if (authUser) {
                setUser(authUser);
            } else {
                console.log("User is signed out");
                setUser(null);
            }
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {

        document.title = 'Privacy Policy | HinterviewGPT';

    }, []);

    return (
        <div className="">
            <header className={`top-0 z-50 transition-all duration-300 border-b bg-[#f5f8fa]`}>
                <nav
                    aria-label="Global"
                    className="max-w-7xl mx-auto flex items-center justify-between py-4 px-2 lg:px-4"
                >
                    <div className="flex lg:flex-1">
                        <a href="/" className="-m-1.5 p-1.5">
                            <span className="sr-only">Your Company</span>
                            <img alt="" src={logoLato} className="h-8 w-auto" />
                        </a>
                    </div>
                    <div className="flex lg:hidden">
                        <button
                            type="button"
                            onClick={() => setMobileMenuOpen(true)}
                            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                        >
                            <span className="sr-only">Open main menu</span>
                            <Bars3Icon aria-hidden="true" className="h-6 w-6" />
                        </button>
                    </div>
                    <div className="hidden lg:flex lg:gap-x-12">
                        {navigation.map((item) => (
                            <a
                                key={item.name}
                                href={item.href}
                                className="text-sm font-semibold leading-6 text-gray-900"
                            >
                                {item.name}
                            </a>
                        ))}
                    </div>
                    <div className="hidden lg:flex lg:flex-1 lg:justify-end items-center">
                        {user ? (
                            <a
                                href="/activity"
                                className="text-sm font-semibold leading-6 text-gray-900"
                            >
                                App <span aria-hidden="true">&rarr;</span>
                            </a>
                        ) : (
                            <>
                                <a
                                    href="/auth?type=login"
                                    className="rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    Log In
                                </a>
                                <a
                                    href="/auth?type=register"
                                    className="ml-4 rounded-md border border-indigo-600 px-4 py-2 text-sm font-semibold text-indigo-600 hover:bg-indigo-600 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    Register
                                </a>
                            </>
                        )}
                    </div>
                </nav>

                {/* Mobile Menu */}
                <Dialog open={mobileMenuOpen} onClose={setMobileMenuOpen} className="lg:hidden">
                    <div className="fixed inset-0 z-50" />
                    <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                        <div className="flex items-center justify-between">
                            <a href="/" className="-m-1.5 p-1.5">
                                <span className="sr-only">Your Company</span>
                                <img
                                    alt=""
                                    src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                                    className="h-8 w-auto"
                                />
                            </a>
                            <button
                                type="button"
                                onClick={() => setMobileMenuOpen(false)}
                                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                            >
                                <span className="sr-only">Close menu</span>
                                <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                            </button>
                        </div>
                        <div className="mt-6 flow-root">
                            <div className="-my-6 divide-y divide-gray-500/10">
                                <div className="space-y-2 py-6">
                                    {navigation.map((item) => (
                                        <a
                                            key={item.name}
                                            href={item.href}
                                            className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                        >
                                            {item.name}
                                        </a>
                                    ))}
                                </div>
                                <div className="py-6">
                                    {user ? (
                                        <a
                                            href="/activity"
                                            className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                        >
                                            App
                                        </a>
                                    ) : (
                                        <>
                                            <a
                                                href="/auth?type=login"
                                                className="rounded-md bg-indigo-600 px-4 py-2 text-base font-semibold text-white hover:bg-indigo-500"
                                            >
                                                Log In
                                            </a>
                                            <a
                                                href="/auth?type=register"
                                                className="ml-4 rounded-md border border-indigo-600 px-4 py-2 text-base font-semibold text-indigo-600 hover:bg-indigo-600 hover:text-white"
                                            >
                                                Register
                                            </a>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </DialogPanel>
                </Dialog>
            </header>


            <main className="p-4 lg:px-28 mt-4">
                <h1 className="text-2xl font-bold mb-4">Privacy Policy</h1>

                <section className="mb-6">
                    <h2 className="text-xl font-semibold">Introduction</h2>
                    <p>
                        Welcome to HinterviewGPT. We are committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner. This Privacy Policy outlines how we collect, use, and protect your information.
                    </p>
                </section>

                <section className="mb-6">
                    <h2 className="text-xl font-semibold">Data Collection and Usage</h2>
                    <p>
                        HinterviewGPT collects and saves the following data to enhance your experience:
                    </p>
                    <ul className="list-disc list-inside ml-4">
                        <li>Questions added through HinterviewGPT generation or manual generation.</li>
                        <li>Generated solutions and user-created solutions that are submitted.</li>
                        <li>HinterviewGPT's evaluation of submitted solutions.</li>
                        <li>Usage statistics, including the number of questions added and solutions submitted.</li>
                    </ul>
                    <p>
                        You have the ability to delete questions that you have added and also the solutions that you have submitted.
                    </p>
                </section>

                <section className="mb-6">
                    <h2 className="text-xl font-semibold">Handling of OpenAI API Keys</h2>
                    <p>
                        HinterviewGPT values your privacy and does not store your OpenAI API keys on our backend servers. These API keys are only used in your browser to interact with the OpenAI API and are kept in your browser's local storage. This ensures that your API keys remain secure and private to you.
                    </p>
                </section>

                <section className="mb-6">
                    <h2 className="text-xl font-semibold">Chat Privacy</h2>
                    <p>
                        Please note that nothing you share in the HinterviewGPT chat is ever saved. This ensures that your conversations remain private and are not stored or accessible by anyone, including us.
                    </p>
                </section>

                <section className="mb-6">
                    <h2 className="text-xl font-semibold">Data Security</h2>
                    <p>
                        We take appropriate measures to protect your data from unauthorized access, alteration, or destruction. However, please be aware that no security measures are perfect or impenetrable, and we cannot guarantee absolute security of your data.
                    </p>
                </section>

                <section className="mb-6">
                    <h2 className="text-xl font-semibold">Your Rights</h2>
                    <p>
                        You have the right to access, correct, or delete any personal data that we hold about you. If you wish to exercise these rights, please contact us at the email provided below.
                    </p>
                </section>

                <section className="mb-6">
                    <h2 className="text-xl font-semibold">Changes to This Policy</h2>
                    <p>
                        We may update this Privacy Policy from time to time to reflect changes in our practices or legal obligations. We encourage you to review this policy periodically to stay informed about how we are protecting your information.
                    </p>
                </section>

                <section className="mb-6">
                    <h2 className="text-xl font-semibold">Contact Us</h2>
                    <p>
                        If you have any questions or concerns about this Privacy Policy, please contact us at support@hinterviewgpt.com.
                    </p>
                </section>
            </main>
            <div className="bg-white border-t border-gray-200 mt-auto">
                <div className="mx-auto max-w-7xl py-3 px-4 sm:px-6 lg:px-8 flex justify-between items-center text-sm">
                    <p className="text-gray-500">
                        © 2024 HinterviewGPT. All rights reserved.
                    </p>
                    <div>
                        <a href="/privacy" className="text-indigo-600 hover:text-indigo-900 ml-4">
                            Privacy Policy
                        </a>
                        <a href="/tos" className="text-indigo-600 hover:text-indigo-900 ml-4">
                            Terms of Service
                        </a>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default PrivacyPolicy;
