import { useEffect, useState } from 'react';
import { auth } from './config/firebase';
import { User } from "firebase/auth";
import { db } from './config/firebase';
import { doc, getDoc, getDocs, collection, query, where, addDoc } from 'firebase/firestore';
import Navbar from './navbar';
import { useNavigate } from 'react-router-dom';
import { httpsCallable } from 'firebase/functions';
import { functions } from './config/firebase';


interface PortalLinkResponse {
    url: string;
}

const Account = () => {
    const [user, setUser] = useState<User | null>(null); // User state
    const [userSet, setUserSet] = useState(false); // New state variable
    const [plan, setPlan] = useState('');
    const [apiKey, setApiKey] = useState<string>(() => localStorage.getItem('apiKey') || '');
    const [isEditable, setIsEditable] = useState<boolean>(false);
    const [isObscured, setIsObscured] = useState<boolean>(true);
    const [subscriptions, setSubscriptions] = useState<any[]>([]); // Subscriptions state
    const [cancelAtNextPeriod, setCancelAtNextPeriod] = useState<boolean>(false);
    const [subscriptionStatus, setSubscriptionStatus] = useState<string>('Inactive'); // Subscription status state
    const [trialRemaining, setTrialRemaining] = useState<number | null>(null);
    const [hasAccess, setHasAccess] = useState<boolean>(false);
    const [usageStats, setUsageStats] = useState<{ [key: string]: number }>({}); // State to store API usage stats


    useEffect(() => {
        const fetchSubscriptions = async () => {
            const currentUser = auth.currentUser;
            if (!currentUser) return;

            const subscriptionsRef = collection(db, 'customers', currentUser.uid, 'subscriptions');
            const activeSubscriptionsQuery = query(subscriptionsRef, where('status', 'in', ['trialing', 'active']));

            const subscriptionSnap = await getDocs(activeSubscriptionsQuery);
            const subs: any[] = [];

            subscriptionSnap.forEach((doc) => {
                subs.push(doc.data());
            });

            if (subs.length > 0) {
                const activeSubscription = subs[0];
                if (activeSubscription.cancel_at_period_end) {
                    setCancelAtNextPeriod(true);
                }

                console.log(activeSubscription.items[0]);
                setSubscriptionStatus(activeSubscription.status);
                const planNickname = activeSubscription.items[0]?.price?.product?.name || 'None'; // Fetch plan nickname
                setPlan(planNickname);
                return;
            } else {
                setPlan('None');
            }

            // 2. Check for free trial eligibility (7 days after sign-up)
            const signUpDate = new Date(currentUser.metadata.creationTime!); // Firebase stores sign-up date
            const currentDate = new Date();
            const timeDiff = currentDate.getTime() - signUpDate.getTime();
            const daysSinceSignUp = Math.floor(timeDiff / (1000 * 3600 * 24));

            // If within 7-day trial period
            if (daysSinceSignUp < 7) {
                setTrialRemaining(7 - daysSinceSignUp);
                console.log("User has a free trial");
                setHasAccess(true);
                return;
            }

            setHasAccess(false);
            setSubscriptionStatus('Inactive');
        };

        fetchSubscriptions();
    }, [user]);

    const navigate = useNavigate();


    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((authUser) => {
            if (authUser) {
                setUser(authUser);
                setUserSet(true);
                fetchUsageStats(authUser);

            } else {
                setUser(null);
                setUserSet(false);
                navigate('/auth');
            }
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {

        document.title = 'Account Information | HinterviewGPT';

    }, []);

    const redirectToCustomerPortal = async () => {
        const functionRef = httpsCallable<{}, PortalLinkResponse>(functions, 'ext-firestore-stripe-payments-createPortalLink');
        try {
            const { data } = await functionRef({
                returnUrl: window.location.origin, // Redirect the user back to your app after they manage their subscription
            });
            window.location.assign(data.url); // Now TypeScript knows 'data' has a 'url' property
        } catch (error) {
            console.error('Error creating portal link:', error);
        }
    };

    const fetchUsageStats = async (authUser: User) => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth() + 1; // Months are 0-indexed, so add 1
        const userId = authUser.uid;

        // Reference to the userUsage document for the current month
        const userUsageRef = doc(db, 'userUsage', `${userId}_${year}_${month}`);

        try {
            const userUsageSnap = await getDoc(userUsageRef);
            if (userUsageSnap.exists()) {
                const data = userUsageSnap.data();
                setUsageStats(data.models || {});
            } else {
                setUsageStats({});
            }
        } catch (error) {
            console.error('Error fetching usage stats:', error);
        }
    };


    return (

        <div className="flex flex-col min-h-screen">
            <Navbar currentNav="Account" />
            <div className="flex-grow bg-white">
                <div className="mx-auto max-w-6xl py-6 px-4 sm:px-6 lg:px-4 mt-2">
                    <h2 className="text-2xl font-bold mb-12 text-gray-800">Account Information</h2>

                    <div className="mt-4 border-t border-gray-200">
                        <dl className="divide-y divide-gray-200">
                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900">Full name</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{user?.displayName}</dd>
                            </div>

                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900">Email address</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{user?.email}</dd>
                            </div>

                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900">Plan Type</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                    {plan === '7-Day Free Trial' && trialRemaining !== null
                                        ? `7-Day Free Trial (${trialRemaining} days remaining)`
                                        : plan}

                                </dd>
                            </div>

                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900">Subscription Status</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                    {cancelAtNextPeriod
                                        ? `${subscriptionStatus} (Cancelled at next period)`
                                        : subscriptionStatus}

                                    <button
                                        className="rounded-md bg-blue-600 text-white px-4 py-1 ml-8"
                                        onClick={redirectToCustomerPortal}
                                    >
                                        Manage Subscription
                                    </button>
                                </dd>
                            </div>



                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900">Sign Up Date</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{user?.metadata.creationTime}</dd>
                            </div>

                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900">Last Sign-in Date</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{user?.metadata.lastSignInTime}</dd>
                            </div>


                        </dl>
                    </div>
                    <div className="mt-8">
                        <h3 className="text-xl font-bold mb-4 text-gray-800">Usage Stats</h3>
                        {Object.keys(usageStats).length > 0 ? (
                            <div>
                                <ul>
                                    {Object.entries(usageStats).map(([model, count]) => (
                                        <li key={model} className="text-sm text-gray-700">
                                            <strong>{model}</strong>: {count} calls
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ) : (
                            <p className="text-sm text-gray-500">No usage data for this month.</p>
                        )}
                    </div>
                </div>
            </div>

            <div className="bg-white border-t border-gray-200 mt-auto">
                <div className="mx-auto max-w-7xl py-3 px-4 sm:px-6 lg:px-8 flex justify-between items-center text-sm">
                    <p className="text-gray-500">
                        © 2024 HinterviewGPT. All rights reserved.
                    </p>
                    <div>
                        <a href="/privacy" className="text-indigo-600 hover:text-indigo-900 ml-4">
                            Privacy Policy
                        </a>
                        <a href="/tos" className="text-indigo-600 hover:text-indigo-900 ml-4">
                            Terms of Service
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Account;
