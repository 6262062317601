import Navbar from './navbar';
import ActivityCalendar from 'react-activity-calendar';
import { Tooltip as MuiTooltip } from '@mui/material';
import getLastYearActivity from './utils/getActivity';
import React, { useState, useEffect } from 'react';
import { auth } from './config/firebase';
import { User } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { db } from './config/firebase';
import { Chip } from '@mui/material';
import { ThemeInput } from 'react-activity-calendar';
import { useNavigate } from 'react-router-dom';
import { ChartBarIcon, EnvelopeIcon, PlusIcon, UserIcon } from '@heroicons/react/24/outline';
import { IconCloudUpload, IconTag } from '@tabler/icons-react';
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/20/solid'
import { collection, query, where, getDocs } from 'firebase/firestore';
import { CheckCircleIcon } from '@heroicons/react/20/solid'; // Use this instead of BadgeCheckIcon
import { Popover } from '@headlessui/react';
import flamingo from './assets/_11b023d4-6379-4994-8da9-113dd5f8d912.jpeg';
import salmon from './assets/_de297e1a-070e-4710-9629-910aa2e6eac8.jpeg';
import crocodile from './assets/_1fdcdd09-4444-4eca-bc42-aa2c16ea43c6.jpeg';
import squid from './assets/_e8edb4bd-d213-4397-a17a-6a1ba6ba74c6.jpeg'
import { Dialog } from '@headlessui/react';
import { subMonths, format, eachDayOfInterval, subDays } from 'date-fns';



interface TotalActivity {
    date: string;
    addCount: number;
    submitCount: number;
}

interface Activity {
    date: string;
    count: number;
    level: number;
}

type QuestionType = {
    qRef: string;
    title: string;
    tags: string[];
    difficulty: string;
    answerType: string;
};

function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
}

type StatItem = {
    name: string;
    stat: string;
    previousStat: string;
    change: string;
    changeType: 'increase' | 'decrease';
};

type Badge = {
    title: string;
    description: string;
    count: number;
    condition: boolean;
    image: string;
    dates: string[]; // Add the dates property
};

type BadgeDates = {
    flamingo: string[];
    salmon: string[];
    alligator: string[];
    squid: string[];
};



const Activity = () => {
    const [user, setUser] = useState<User | null>(null); // User state
    const [TotalActivity, setTotalActivity] = useState<TotalActivity[]>([]);
    const [activity, setActivity] = useState<Activity[]>([]);
    const [originalData, setOriginalData] = useState<QuestionType[]>([]);
    const [totalSubmissions, setTotalSubmissions] = useState(0); // New state for total submissions
    const [totalAddedQuestions, setTotalAddedQuestions] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [stats, setStats] = useState<StatItem[]>([]);
    const [subscriptions, setSubscriptions] = useState<any[]>([]); // Subscriptions state
    const [plan, setPlan] = useState('');
    const [subscriptionStatus, setSubscriptionStatus] = useState<string>('Inactive'); // Subscription status state
    const [hasAccess, setHasAccess] = useState<boolean>(false);
    const [trialRemaining, setTrialRemaining] = useState<number | null>(null);
    const [cancelAtNextPeriod, setCancelAtNextPeriod] = useState<boolean>(false);
    const [daysActiveStreak, setDaysActiveStreak] = useState(0); // New state for active streak
    const [openBadgeDialog, setOpenBadgeDialog] = useState<number | null>(null); // Track which badge's dialog is open
    const [totalDaysActive, setTotalDaysActive] = useState(0);
    const [last30DaysAddedQuestions, setLast30DaysAddedQuestions] = useState(0);
    const [last30DaysSubmissions, setLast30DaysSubmissions] = useState(0);
    const [last30DaysActiveDays, setLast30DaysActiveDays] = useState(0);
    const [prev30DaysAddedQuestions, setPrev30DaysAddedQuestions] = useState(0);
    const [prev30DaysSubmissions, setPrev30DaysSubmissions] = useState(0);
    const [prev30DaysActiveDays, setPrev30DaysActiveDays] = useState(0);
    const [addedQuestionsChange, setAddedQuestionsChange] = useState('N/A');
    const [submissionsChange, setSubmissionsChange] = useState('N/A');
    const [activeDaysChange, setActiveDaysChange] = useState('N/A');

    const [badgeDates, setBadgeDates] = useState<BadgeDates>({
        flamingo: [],
        salmon: [],
        alligator: [],
        squid: [],
    });

    const badges: Badge[] = [
        {
            title: 'First Time Flamingo',
            description: 'Made your first submission or added your first question',
            count: (totalSubmissions >= 1 ? 1 : 0) + (totalAddedQuestions >= 1 ? 1 : 0),
            condition: totalSubmissions >= 1 || totalAddedQuestions >= 1,
            image: flamingo,
            dates: badgeDates.flamingo, // Use the dates from fetchAndSetActivities
        },
        {
            title: 'Submission Slamin\' Salmon',
            description: 'Added 10 or more questions',
            count: Math.floor(totalAddedQuestions / 10),
            condition: totalAddedQuestions >= 10,
            image: salmon,
            dates: badgeDates.salmon,
        },
        {
            title: 'Question Addin\' Alligator',
            description: 'Submitted 10 or more solutions',
            count: Math.floor(totalSubmissions / 10),
            condition: totalSubmissions >= 10,
            image: crocodile,
            dates: badgeDates.alligator,
        },
        {
            title: 'Activity Streakin\' Squid',
            description: 'Activity for 3 consecutive days',
            count: badgeDates.squid.length,
            condition: badgeDates.squid.length > 0,
            image: squid,
            dates: badgeDates.squid,
        },

    ];




    useEffect(() => {
        const checkAccess = async () => {
            if (!user) return;

            // 1. Check subscription status
            const subscriptionsRef = collection(db, 'customers', user.uid, 'subscriptions');
            const activeSubscriptions = query(subscriptionsRef, where('status', 'in', ['active', 'trialing']));
            const subscriptionSnap = await getDocs(activeSubscriptions);

            const subs: any[] = [];

            subscriptionSnap.forEach((doc) => {
                subs.push(doc.data());
            });

            if (subs.length > 0) {
                const activeSubscription = subs[0];
                if (activeSubscription.cancel_at_period_end) {
                    setCancelAtNextPeriod(true);
                }
                setSubscriptionStatus(activeSubscription.status);
                const planNickname = activeSubscription.items[0]?.price?.product?.name || '7-Day Free Trial'; // Fetch plan nickname
                setPlan(planNickname);
                return;
            }

            // 2. Check for free trial eligibility (7 days after sign-up)
            const signUpDate = new Date(user.metadata.creationTime!); // Firebase stores sign-up date
            const currentDate = new Date();
            const timeDiff = currentDate.getTime() - signUpDate.getTime();
            const daysSinceSignUp = Math.floor(timeDiff / (1000 * 3600 * 24));

            // If within 7-day trial period
            if (daysSinceSignUp < 7) {
                setTrialRemaining(7 - daysSinceSignUp);
                console.log("User has a free trial");
                setHasAccess(true);
                return;
            }

            setHasAccess(false);
            setSubscriptionStatus('Inactive');

        };

        if (user) {
            checkAccess();
        }
    }, [user]);



    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((authUser) => {
            if (authUser) {
                setUser(authUser);
                fetchAndSetActivities(authUser.uid);
            } else {
                setUser(null);
                setTotalActivity([]);
                navigate('/auth');

            }
        });

        return () => unsubscribe(); // Unsubscribe on unmount
    }, []);

    useEffect(() => {

        document.title = 'Overview | HinterviewGPT';

    }, []);

    const navigate = useNavigate();

    useEffect(() => {
        if (user) {
            // Only call getLeetCodeQuestions if the user has been set
            getQuestions();
        }
    }, [user]);

    const getQuestions = async () => {

        if (!user) {
            return;
        }

        if (user) {
            try {

                const userQuestionsListRef = doc(db, 'userQuestionList', user.uid);
                const userQuestionsListDoc = await getDoc(userQuestionsListRef);

                if (userQuestionsListDoc.exists()) {
                    // Explicitly define the type for question_data
                    const userQuestionsList = userQuestionsListDoc.data().questions || [];

                    // Convert the 'question_data' field to an array of QuestionType
                    const queriedData: QuestionType[] = userQuestionsList.map((question: QuestionType) => {
                        const { title, difficulty, qRef, tags, answerType } = question;

                        return {
                            qRef,
                            title,
                            tags,
                            difficulty,
                            answerType,
                        };
                    });

                    setOriginalData(queriedData);
                }

            } catch (err) {
                console.error('Error getting documents: ', err);
            }
        }
    };


    const calculatePercentageChange = (current: any, previous: any) => {
        if (previous === 0) return 'N/A';
        return ((current - previous) / previous * 100).toFixed(2);
    };

    const fetchAndSetActivities = async (userId: string) => {
        try {
            const today = new Date();

            const activities = await getLastYearActivity(userId);

            // 1. For the activity calendar: last 12 months
            const pastYear = subMonths(today, 12);
            const calendarDays = eachDayOfInterval({ start: pastYear, end: today }).map(date => format(date, 'yyyy-MM-dd'));

            const activityArray: Activity[] = calendarDays.map(date => {
                const dayActivity = activities[date] || { addCount: 0, submitCount: 0 };
                const totalCount = dayActivity.addCount + dayActivity.submitCount;
                return {
                    date,
                    count: totalCount,
                    level: totalCount > 10 ? 10 : totalCount,
                };
            });

            setActivity(activityArray);
            setIsLoading(false);

            // 2. For badge calculations: all activity dates
            const activityDates = Object.keys(activities);
            activityDates.sort();

            const earliestDate = activityDates.length > 0 ? new Date(activityDates[0]) : today;

            const allDays = eachDayOfInterval({ start: earliestDate, end: today }).map(date => format(date, 'yyyy-MM-dd'));

            // Initialize totalDaysActive
            let totalDaysActive = 0;

            // New variables for last 30 days
            const last30DaysStart = subDays(today, 29); // Including today
            const last30Days = eachDayOfInterval({ start: last30DaysStart, end: today }).map(date => format(date, 'yyyy-MM-dd'));


            const prev30DaysStart = subDays(last30DaysStart, 30); // The 30 days before the last 30 days
            const prev30Days = eachDayOfInterval({ start: prev30DaysStart, end: subDays(today, 30) }).map(date => format(date, 'yyyy-MM-dd'));

            let last30DaysAddedQuestions = 0;
            let last30DaysSubmissions = 0;
            let last30DaysActiveDays = 0;

            let prev30DaysAddedQuestions = 0;
            let prev30DaysSubmissions = 0;
            let prev30DaysActiveDays = 0;

            // Track badge dates
            let badgeDates: BadgeDates = {
                flamingo: [],
                salmon: [],
                alligator: [],
                squid: [],
            };

            // Track running totals for badges
            let totalQuestions = 0;
            let totalSubmissions = 0;
            let activeStreak = 0;
            let prevDate: any = null;

            // Thresholds to track when the next badge is earned
            let nextSalmonThreshold = 10;
            let nextAlligatorThreshold = 10;
            let nextSquidThreshold = 3;

            // Iterate over all dates for badge calculations
            allDays.forEach(date => {
                const dayActivity = activities[date] || { addCount: 0, submitCount: 0 };

                totalQuestions += dayActivity.addCount;
                totalSubmissions += dayActivity.submitCount;

                // Increment totalDaysActive if there's any activity
                if (dayActivity.addCount > 0 || dayActivity.submitCount > 0) {
                    totalDaysActive += 1;

                    // Track activity streaks for the squid badge (every 3 consecutive days of activity)
                    if (prevDate && (new Date(date).getTime() - new Date(prevDate).getTime()) / (1000 * 3600 * 24) === 1) {
                        activeStreak++;
                    } else {
                        activeStreak = 1;
                    }
                    prevDate = date;

                    if (activeStreak >= nextSquidThreshold) {
                        badgeDates.squid.push(date);  // Add the date when the streak reaches the threshold
                        nextSquidThreshold += 3;      // Increment the threshold by 3
                    }
                }

                // Track the count and dates for the salmon badge (every 10 questions)
                if (totalQuestions >= nextSalmonThreshold) {
                    badgeDates.salmon.push(date);  // Add the date when the next threshold is reached
                    nextSalmonThreshold += 10;     // Increment the threshold by 10
                }

                // Track the count and dates for the alligator badge (every 10 submissions)
                if (totalSubmissions >= nextAlligatorThreshold) {
                    badgeDates.alligator.push(date);  // Add the date when the next threshold is reached
                    nextAlligatorThreshold += 10;     // Increment the threshold by 10
                }

                // First-time Flamingo badge (track first question and first submission separately)
                if (totalQuestions >= 1 && badgeDates.flamingo.length === 0) {
                    badgeDates.flamingo.push(date);  // First question added
                }

                if (totalSubmissions >= 1 && badgeDates.flamingo.length === 1) {
                    badgeDates.flamingo.push(date);  // First submission made, now adding the second date
                }
            });

            // Calculate last 30 days data
            last30Days.forEach(date => {
                const dayActivity = activities[date] || { addCount: 0, submitCount: 0 };

                last30DaysAddedQuestions += dayActivity.addCount;
                last30DaysSubmissions += dayActivity.submitCount;

                if (dayActivity.addCount > 0 || dayActivity.submitCount > 0) {
                    last30DaysActiveDays += 1;
                }
            });

            prev30Days.forEach(date => {
                const dayActivity = activities[date] || { addCount: 0, submitCount: 0 };

                prev30DaysAddedQuestions += dayActivity.addCount;
                prev30DaysSubmissions += dayActivity.submitCount;

                if (dayActivity.addCount > 0 || dayActivity.submitCount > 0) {
                    prev30DaysActiveDays += 1;
                }
            });

            // Update state with the badge counts and dates
            setTotalAddedQuestions(totalQuestions);
            setTotalSubmissions(totalSubmissions);
            setTotalDaysActive(totalDaysActive);
            setDaysActiveStreak(activeStreak);
            setBadgeDates(badgeDates);

            // Update state with last 30 days data
            setLast30DaysAddedQuestions(last30DaysAddedQuestions);
            setLast30DaysSubmissions(last30DaysSubmissions);
            setLast30DaysActiveDays(last30DaysActiveDays);

            const addedQuestionsChange = calculatePercentageChange(last30DaysAddedQuestions, prev30DaysAddedQuestions);
            const submissionsChange = calculatePercentageChange(last30DaysSubmissions, prev30DaysSubmissions);
            const activeDaysChange = calculatePercentageChange(last30DaysActiveDays, prev30DaysActiveDays);

            setPrev30DaysAddedQuestions(prev30DaysAddedQuestions);
            setPrev30DaysSubmissions(prev30DaysSubmissions);
            setPrev30DaysActiveDays(prev30DaysActiveDays);

            setAddedQuestionsChange(addedQuestionsChange);
            setSubmissionsChange(submissionsChange);
            setActiveDaysChange(activeDaysChange);

            setStats([
                {
                    name: 'Questions Added',
                    stat: last30DaysAddedQuestions.toString(),
                    previousStat: prev30DaysAddedQuestions.toString(),
                    change: addedQuestionsChange,
                    changeType: parseFloat(addedQuestionsChange) > 0 ? 'increase' : 'decrease',
                },
                {
                    name: 'Submissions',
                    stat: last30DaysSubmissions.toString(),
                    previousStat: prev30DaysSubmissions.toString(),
                    change: submissionsChange,
                    changeType: parseFloat(submissionsChange) > 0 ? 'increase' : 'decrease',
                },
                {
                    name: 'Active Days',
                    stat: last30DaysActiveDays.toString(),
                    previousStat: prev30DaysActiveDays.toString(),
                    change: activeDaysChange,
                    changeType: parseFloat(activeDaysChange) > 0 ? 'increase' : 'decrease',
                },
            ]);

        } catch (error) {
            console.error("Error fetching activities:", error);
        }
    };




    // Define the theme object with 11 levels of green shades
    const theme: ThemeInput = {
        light: [
            '#ebedf0', // 0 activity
            '#c6e48b', // 1-2 activities
            '#b4dd7f', // 3-4 activities
            '#a1d573', // 5-6 activities
            '#8ecd67', // 7-8 activities
            '#7bc55b', // 9-10 activities
            '#68bd4f', // 11-12 activities
            '#56b543', // 13-14 activities
            '#439d37', // 15-16 activities
            '#31852b', // 17-18 activities
            '#196127', // 19+ activities
        ],
        dark: [
            '#161b22', // 0 activity
            '#0e4429', // 1-2 activities
            '#0d3d25', // 3-4 activities
            '#0b361f', // 5-6 activities
            '#093019', // 7-8 activities
            '#072912', // 9-10 activities
            '#06220d', // 11-12 activities
            '#041b08', // 13-14 activities
            '#031403', // 15-16 activities
            '#021300', // 17-18 activities
            '#39d353', // 19+ activities
        ],
    };





    return (
        <div className="min-h-screen flex flex-col mx-auto " >
            {/* Navbar at the top */}
            <Navbar currentNav="Overview" />

            {/* Grid layout starts below the Navbar */}
            <div className="flex-grow max-w-7xl mx-auto grid grid-cols-8 gap-4 mt-4">
                {/* Left Column: Takes up 1/5 of the width */}
                <div className="col-span-2 p-4">
                    {/* Left column content */}
                    <div className="w-full py-4 flex flex-col items-center justify-center">
                        {user ? (
                            <div className="text-left">
                                {/* User Avatar and Details */}
                                {user.photoURL ? (
                                    <img
                                        src={user.photoURL}
                                        alt="User Avatar"
                                        className="w-64 h-64 rounded-full mb-4 mx-auto border-2 border-gray-300 shadow-lg"
                                    />
                                ) : (
                                    <UserIcon className="w-64 h-64 rounded-full mb-4 mx-auto text-gray-500 border-2 border-gray-300 shadow-lg" />
                                )}
                                <p className="text-2xl font-semibold leading-6 text-gray-900 mb-2">{user.displayName}</p>
                                <p className="text-sm text-md font-medium text-gray-600 mb-2">{user.email}</p>

                                <button
                                    onClick={() => navigate('/account')}
                                    className="rounded-md w-full bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                >
                                    Account Details
                                </button>

                                {/* Plan and Status Section */}
                                <div className="mt-4  text-md font-medium text-gray-600">
                                    {/* Display Plan */}
                                    {plan && trialRemaining === null && (
                                        <div className="flex items-center">
                                            <p className="text-sm"><span className='font-bold'>Plan:</span> {plan}</p>
                                            {/* <span className="ml-1 inline-flex items-center bg-green-100 text-green-700 text-xs font-medium px-2 py-1 rounded-md"> */}
                                            {/* {plan} */}
                                            {/* </span> */}
                                        </div>
                                    )}

                                    {/* Display Free Trial */}
                                    {trialRemaining !== null && (
                                        <>
                                            <div className="flex items-center">
                                                <p className="text-sm"><span className='font-bold'>Plan:</span>  7-Day Free Trial</p>
                                                {/* <span className="ml-1 inline-flex items-center bg-blue-100 text-blue-700 text-xs font-medium px-2 py-1 rounded-md">
                                                    7-Day Free Trial
                                                </span> */}
                                            </div>
                                            <div className="flex items-center">
                                                <p className="text-sm"><span className='font-bold'>Days Remaining:</span> {trialRemaining} days</p>
                                                {/* <span className="ml-1 inline-flex items-center bg-yellow-100 text-yellow-700 text-xs font-medium px-2 py-1 rounded-md">
                                                    {trialRemaining} days
                                                </span> */}
                                            </div>
                                        </>
                                    )}

                                    {/* Display Subscription Status */}
                                    <div className="flex items-center">
                                        <p className="text-sm"><span className='font-bold'>Status:</span> {cancelAtNextPeriod ? 'Active (Cancelled)' : subscriptionStatus}</p>
                                        {/* <span className={`ml-1 inline-flex items-center px-2 py-1 text-xs font-medium rounded-md ${subscriptionStatus === 'active' ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'}`}>
                                            {cancelAtNextPeriod ? 'Active (Cancelled)' : subscriptionStatus}
                                        </span> */}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <p>No user information available</p>
                        )}

                    </div>
                    <hr className="border-gray-300" />
                    <div className="col-span-2 p-4">
                        {/* Achievements Section */}
                        <p className="text-lg font-semibold leading-6 text-gray-900 mb-4 mt-2">Achievements</p>
                        <div className="grid grid-cols-2 gap-4">
                            {badges.filter(badge => badge.condition).map((badge, index) => (
                                <div key={index}>
                                    <button
                                        onClick={() => setOpenBadgeDialog(index)}
                                        className="flex flex-col items-center focus:outline-none"
                                    >
                                        <div className="relative w-20 h-20 flex items-center justify-center">
                                            <div className="absolute w-full h-full rounded-full border-1 border-gray-300 z-10"></div>
                                            <img src={badge.image} alt={badge.title} className="w-full h-full border rounded-full object-cover z-0" />
                                            {badge.count > 1 && (
                                                <div className="absolute top-0 right-0 bg-red-500 text-white text-xs font-bold rounded-full h-6 w-6 flex items-center justify-center z-10">
                                                    x{badge.count}
                                                </div>
                                            )}
                                        </div>
                                    </button>

                                    <Dialog open={openBadgeDialog === index} onClose={() => setOpenBadgeDialog(null)} className="relative z-10">
                                        <div className="fixed inset-0 bg-black bg-opacity-60 transition-opacity" aria-hidden="true" />
                                        <div className="fixed inset-0 z-10 overflow-y-auto">
                                            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
                                                <Dialog.Panel
                                                    className="relative transform overflow-hidden rounded-lg bg-white shadow-lg transition-all sm:my-8 sm:w-full sm:max-w-xs"
                                                    style={{ maxHeight: '80vh', overflowY: 'auto' }} // Reduced max-width to sm:max-w-xs
                                                >
                                                    {/* Close Button */}
                                                    <button
                                                        type="button"
                                                        onClick={() => setOpenBadgeDialog(null)}
                                                        className="absolute top-3 right-3 text-gray-400 hover:text-gray-600 focus:outline-none"
                                                    >
                                                        <span className="sr-only">Close</span>
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                                        </svg>
                                                    </button>

                                                    {/* Image Part with Distinctive Background */}
                                                    <div className="bg-blue-100 px-6 pb-6 pt-8">
                                                        {/* Larger Badge Image */}
                                                        <div className="mx-auto flex h-40 w-40 items-center justify-center mb-4">
                                                            <img
                                                                src={badge.image}
                                                                alt={badge.title}
                                                                className="h-full w-full rounded-full object-cover shadow-md"
                                                            />
                                                        </div>
                                                    </div>

                                                    {/* Text Part without Background */}
                                                    <div className="px-6 pt-4">
                                                        <div className="text-center">
                                                            <Dialog.Title as="h3" className="text-lg font-bold leading-6 text-gray-900">
                                                                {badge.title}
                                                            </Dialog.Title>
                                                            <div className="mt-2">
                                                                <p className="text-sm text-gray-500">{badge.description}</p>
                                                            </div>
                                                        </div>

                                                        {/* Horizontal Line */}
                                                        <hr className="my-4 border-gray-300" />

                                                        {badge.dates.length > 0 && (
                                                            <div>
                                                                <p className="text-sm font-semibold text-gray-700">Earned on:</p>
                                                                <ul className="mt-2 max-h-32 overflow-y-auto space-y-1 text-sm text-gray-500 mb-4">
                                                                    {badge.dates.map((date, dateIndex) => (
                                                                        <li key={dateIndex}>
                                                                            {new Date(date).toLocaleDateString('en-US', {
                                                                                year: 'numeric',
                                                                                month: 'long',
                                                                                day: 'numeric',
                                                                            })}
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        )}
                                                    </div>
                                                </Dialog.Panel>
                                            </div>
                                        </div>
                                    </Dialog>
                                </div>
                            ))}

                        </div>
                    </div>
                </div>

                {/* Right Column: Takes up 4/5 of the width */}
                <div className="col-span-6 flex flex-col gap-2">
                    {/* Section 1 */}

                    <div className="p-4">
                        <div className="flex justify-between items-center">
                            <h3 className="text-base font-semibold leading-6 text-gray-900">
                                Recently Added Questions
                            </h3>
                            <h3
                                className="text-xs font-semibold cursor-pointer text-indigo-600 hover:text-indigo-900"
                                onClick={() => navigate(`/questions`)}
                            >
                                view all questions
                            </h3>
                        </div>
                        {originalData.length > 0 ? (
                            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-4 mt-2">
                                {originalData.slice(-6).reverse().map((question, index) => (
                                    <div
                                        key={index}
                                        className="relative p-4 border border-gray-300 rounded-md flex flex-col justify-between bg-white"
                                    >
                                        <div className="flex justify-between items-start mb-2">
                                            <h3
                                                className="text-sm font-semibold cursor-pointer text-indigo-600 hover:text-indigo-900"
                                                onClick={() => navigate(`/question/${question.qRef}`)}
                                            >
                                                {originalData.length - index}. {question.title}
                                            </h3>
                                            <span className="bg-blue-50 text-blue-700 px-3 py-1 rounded-full text-xs font-medium ring-1 ring-blue-700/10">
                                                {question.answerType}
                                            </span>
                                        </div>
                                        <div className="mt-auto text-gray-600">
                                            {Array.isArray(question.tags) &&
                                                question.tags.map((tag, tagIndex) => (
                                                    <span
                                                        key={tagIndex}
                                                        className="inline-flex items-center rounded-xl bg-gray-400/10 px-2 py-1 text-[10px] font-xs mr-2 text-gray-900 ring-1 ring-inset ring-gray-400/20"
                                                    >
                                                        <IconTag className="w-3 h-3 mr-1" />
                                                        {tag}
                                                    </span>
                                                ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <p className="mt-5 text-center text-gray-500">
                                No questions added yet
                            </p>
                        )}
                    </div>



                    {/* Section 2 */}
                    <div className="p-4">
                        <h3 className="text-base font-semibold leading-6 text-gray-900">Daily Activity</h3>
                        <div className="p-4 mt-2 rounded-md border border-gray-300 bg-white flex justify-center items-center">
                            <ActivityCalendar
                                blockMargin={4}
                                blockRadius={2}
                                blockSize={11}
                                colorScheme="light"
                                data={activity}
                                fontSize={12}
                                maxLevel={10}
                                weekStart={0}
                                theme={theme}
                                showWeekdayLabels
                                loading={isLoading}
                                // hideTotalCount
                                renderBlock={(block, activity) => (
                                    <MuiTooltip title={`${activity.count} activities on ${activity.date}`}>
                                        {block}
                                    </MuiTooltip>
                                )}
                            />
                        </div>
                    </div>

                    <div className="p-4">
                        <h3 className="text-base font-semibold leading-6 text-gray-900">Last 30 Days</h3>

                        <div className="grid grid-cols-1 sm:grid-cols-3 gap-6 mt-2">
                            {/* Questions Added */}
                            <div className="px-4 py-5 sm:p-6 bg-white rounded-lg border border-gray-300 shadow-sm">
                                <dt className="text-base font-normal text-gray-900">Questions Added</dt>
                                <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                                    <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
                                        {last30DaysAddedQuestions}
                                        <span className="ml-2 text-sm font-medium text-gray-500">from {prev30DaysAddedQuestions}</span>
                                    </div>

                                    <div
                                        className={classNames(
                                            parseFloat(addedQuestionsChange) > 0 ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800',
                                            'inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0',
                                        )}
                                    >
                                        {parseFloat(addedQuestionsChange) > 0 ? (
                                            <ArrowUpIcon
                                                aria-hidden="true"
                                                className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-green-500"
                                            />
                                        ) : (
                                            <ArrowDownIcon
                                                aria-hidden="true"
                                                className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-red-500"
                                            />
                                        )}
                                        <span className="sr-only">
                                            {parseFloat(addedQuestionsChange) > 0 ? 'Increased' : 'Decreased'} by
                                        </span>
                                        {addedQuestionsChange}%
                                    </div>
                                </dd>
                            </div>

                            {/* Submissions */}
                            <div className="px-4 py-5 sm:p-6 bg-white rounded-lg border border-gray-300 shadow-sm">
                                <dt className="text-base font-normal text-gray-900">Submissions</dt>
                                <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                                    <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
                                        {last30DaysSubmissions}
                                        <span className="ml-2 text-sm font-medium text-gray-500">from {prev30DaysSubmissions}</span>
                                    </div>

                                    <div
                                        className={classNames(
                                            parseFloat(submissionsChange) > 0 ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800',
                                            'inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0',
                                        )}
                                    >
                                        {parseFloat(submissionsChange) > 0 ? (
                                            <ArrowUpIcon
                                                aria-hidden="true"
                                                className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-green-500"
                                            />
                                        ) : (
                                            <ArrowDownIcon
                                                aria-hidden="true"
                                                className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-red-500"
                                            />
                                        )}
                                        <span className="sr-only">
                                            {parseFloat(submissionsChange) > 0 ? 'Increased' : 'Decreased'} by
                                        </span>
                                        {submissionsChange}%
                                    </div>
                                </dd>
                            </div>

                            {/* Active Days */}
                            <div className="px-4 py-5 sm:p-6 bg-white rounded-lg border border-gray-300 shadow-sm">
                                <dt className="text-base font-normal text-gray-900">Active Days</dt>
                                <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                                    <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
                                        {last30DaysActiveDays}
                                        <span className="ml-2 text-sm font-medium text-gray-500">from {prev30DaysActiveDays}</span>
                                    </div>

                                    <div
                                        className={classNames(
                                            parseFloat(activeDaysChange) > 0 ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800',
                                            'inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0',
                                        )}
                                    >
                                        {parseFloat(activeDaysChange) > 0 ? (
                                            <ArrowUpIcon
                                                aria-hidden="true"
                                                className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-green-500"
                                            />
                                        ) : (
                                            <ArrowDownIcon
                                                aria-hidden="true"
                                                className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-red-500"
                                            />
                                        )}
                                        <span className="sr-only">
                                            {parseFloat(activeDaysChange) > 0 ? 'Increased' : 'Decreased'} by
                                        </span>
                                        {activeDaysChange}%
                                    </div>
                                </dd>
                            </div>
                        </div>
                    </div>


                    <div className="p-4">
                        <h3 className="text-base font-semibold leading-6 text-gray-900">All Time</h3>

                        <div className="grid grid-cols-1 sm:grid-cols-3 gap-6 mt-2 mb-6">
                            {/* Total Questions Added */}
                            <div className="px-4 py-5 sm:p-6 bg-white rounded-lg border border-gray-300 shadow-sm">
                                <dt className="text-base font-normal text-gray-900">Total Questions Added</dt>
                                <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                                    <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
                                        {totalAddedQuestions}
                                    </div>
                                </dd>
                            </div>

                            {/* Total Submissions */}
                            <div className="px-4 py-5 sm:p-6 bg-white rounded-lg border border-gray-300 shadow-sm">
                                <dt className="text-base font-normal text-gray-900">Total Submissions</dt>
                                <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                                    <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
                                        {totalSubmissions}
                                    </div>
                                </dd>
                            </div>

                            {/* Total Days Active */}
                            <div className="px-4 py-5 sm:p-6 bg-white rounded-lg border border-gray-300 shadow-sm">
                                <dt className="text-base font-normal text-gray-900">Total Days Active</dt>
                                <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                                    <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
                                        {totalDaysActive}
                                    </div>
                                </dd>
                            </div>
                        </div>
                    </div>


                </div>
            </div>

            {/* Footer */}
            <div className="bg-white border-t border-gray-200">
                <div className="mx-auto max-w-7xl py-3 px-4 sm:px-6 lg:px-8 flex justify-between items-center text-sm">
                    <p className="text-gray-500">
                        © 2024 HinterviewGPT. All rights reserved.
                    </p>
                    <div>
                        <a href="/privacy" className="text-indigo-600 hover:text-indigo-900 ml-4">
                            Privacy Policy
                        </a>
                        <a href="/tos" className="text-indigo-600 hover:text-indigo-900 ml-4">
                            Terms of Service
                        </a>
                    </div>
                </div>
            </div>
        </div>




    );
};

export default Activity;
