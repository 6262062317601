import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from './navbar';
import { httpsCallable } from 'firebase/functions';
import { functions } from './config/firebase';
import { Firestore, collection, getDocs, query, where, addDoc, onSnapshot } from 'firebase/firestore';
import { db } from './config/firebase';
import { User } from "firebase/auth";
import { auth } from './config/firebase';
import { CheckIcon } from '@heroicons/react/20/solid'


interface PortalLinkResponse {
    url: string;
}

interface Price {
    id: string;  // Add id to represent the price document ID
    currency: string;
    unit_amount: number;
    interval: 'day' | 'week' | 'month' | 'year';
}

interface Product {
    name: string;
    description: string;
    prices: Price[];
    features: string[];
}

const NoAccess = () => {
    const navigate = useNavigate();
    const [products, setProducts] = useState<Product[]>([]);
    const [user, setUser] = useState<User | null>(null);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((authUser) => {
            if (authUser) {
                setUser(authUser);
            } else {
                console.log("User is signed out");
                setUser(null);
            }
        });

        return () => unsubscribe();
    }, []);

    const redirectToCustomerPortal = async () => {
        const functionRef = httpsCallable<{}, PortalLinkResponse>(functions, 'ext-firestore-stripe-payments-createPortalLink');
        try {
            const { data } = await functionRef({
                returnUrl: window.location.origin, // Redirect the user back to your app after they manage their subscription
            });
            window.location.assign(data.url); // Now TypeScript knows 'data' has a 'url' property
        } catch (error) {
            console.error('Error creating portal link:', error);
        }
    };


    useEffect(() => {
        const fetchProducts = async () => {
            const productsCollection = collection(db, 'products');
            const q = query(productsCollection, where('active', '==', true));
            const querySnapshot = await getDocs(q);

            const productsData: Product[] = [];
            for (const productDoc of querySnapshot.docs) {
                const productData = productDoc.data() as Omit<Product, 'prices'>;
                const pricesCollection = collection(productDoc.ref, 'prices');
                const pricesSnapshot = await getDocs(pricesCollection);
                const prices: Price[] = pricesSnapshot.docs.map((priceDoc) => {
                    const priceData = priceDoc.data() as Omit<Price, 'id'>;  // Exclude id
                    return {
                        id: priceDoc.id,  // Manually add the Firestore ID
                        ...priceData,     // Spread the rest of the price data
                    };
                });

                const features = productData.name === 'Bring your own key'
                    ? ['Requires OpenAI API key', 'Pay OpenAI directly for your usage', 'HinterviewGPT never stores your key', 'Access to GPT-4o and GPT-4o-mini', 'Generate interview questions', 'Access to AI tutor', 'AI solution evaluation', 'Priority support', 'Cancel anytime']
                    : productData.name === 'Basic'
                        ? ['250 calls per month for GPT-4o model', '500 calls per month for GPT-4o-mini model', 'Generate interview questions', 'Access to AI tutor', 'AI solution evaluation', 'Priority support', 'Cancel anytime']
                        : productData.name === 'Advanced'
                            ? ['500 calls per month for GPT-4o model', '750 calls per month for GPT-4o-mini model', 'Generate interview questions', 'Access to AI tutor', 'AI solution evaluation', 'Priority support', 'Cancel anytime']
                            : ['Basic feature 1', 'Basic feature 2'];

                productsData.push({ ...productData, prices, features });
            }
            setProducts(productsData);
        };

        fetchProducts();
    }, []);

    const handleCheckout = async (priceId: string) => {
        const user = auth.currentUser;

        if (!user) {
            // If the user isn't logged in, redirect them to the login page
            window.location.href = '/auth';
            return;
        }

        const checkoutSessionRef = collection(db, 'customers', user.uid, 'checkout_sessions');

        try {
            const docRef = await addDoc(checkoutSessionRef, {
                price: priceId,
                success_url: `${window.location.origin}/success`, // Redirect to the success page after payment
                cancel_url: window.location.origin,
            });

            // Listen for the session URL from Stripe and redirect the user
            const unsubscribe = onSnapshot(docRef, (snap) => {
                const { error, url } = snap.data()!;
                if (error) {
                    alert(`An error occurred: ${error.message}`);
                }
                if (url) {
                    window.location.assign(url); // Redirect to Stripe Checkout
                }
            });
        } catch (error) {
            console.error('Error creating checkout session:', error);
            alert('An error occurred while creating the checkout session.');
        }
    };


    return (

        <div className="flex flex-col min-h-screen">
            <Navbar currentNav="" />

            <div className="flex flex-1 flex-col justify-start items-center bg-gray-100 pt-16">
                <div className="max-w-4xl w-full text-center">
                    <h1 className="text-4xl font-bold text-gray-800 mb-6">No Access</h1>
                    <p className="text-lg text-gray-600 mb-2">
                        You currently do not have an active subscription or your free trial has expired.
                        Subscribe to a plan by clicking the button below to continue using HinterviewGPT.
                    </p>
                </div>
                <div className="flow-root max-w-6xl mb-10">
                    {products.length > 0 && (
                        <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-4 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                            {products.map((product, index) => (
                                <div
                                    key={product.name}
                                    className={`ring-1 ring-gray-200 bg-white rounded-3xl p-8 xl:p-10 relative ${index === 1 ? "ring-indigo-600 border-2" : ""
                                        }`} // Highlight the second product
                                >
                                    {index === 1 && (
                                        <div className="absolute -top-3 left-1/2 transform -translate-x-1/2 bg-indigo-600 text-white text-xs font-semibold px-3 py-1 rounded-full">
                                            Most Popular
                                        </div>
                                    )}

                                    <div className="flex items-center justify-between gap-x-4">
                                        <h3 className="text-gray-900 text-lg font-semibold leading-8">
                                            {product.name}
                                        </h3>
                                    </div>
                                    <p className="mt-4 text-sm leading-6 text-gray-600">
                                        {product.description}
                                    </p>
                                    <p className="mt-6 flex items-baseline gap-x-1">
                                        <span className="text-4xl font-bold tracking-tight text-gray-900">
                                            ${product.prices[0].unit_amount / 100}
                                        </span>
                                        <span className="text-sm font-semibold leading-6 text-gray-600">
                                            /month
                                        </span>
                                    </p>
                                    <button
                                        onClick={() => handleCheckout(product.prices[0].id)}
                                        className="w-full bg-indigo-600 text-white shadow-sm hover:bg-indigo-500 mt-6 block rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    >
                                        {user ? "Sign Up" : "Start Free Trial"}
                                    </button>

                                    <ul
                                        role="list"
                                        className="mt-8 space-y-3 text-sm leading-6 text-gray-600 xl:mt-10"
                                    >
                                        {product.features.map((feature) => (
                                            <li key={feature} className="flex gap-x-3">
                                                <CheckIcon
                                                    aria-hidden="true"
                                                    className="h-6 w-5 flex-none text-indigo-600"
                                                />
                                                {feature}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
            {/* Footer */}
            <div className="bg-white border-t border-gray-200">
                <div className="mx-auto max-w-7xl py-3 px-4 sm:px-6 lg:px-8 flex justify-between items-center text-sm">
                    <p className="text-gray-500">
                        © 2024 HinterviewGPT. All rights reserved.
                    </p>
                    <div>
                        <a href="/privacy" className="text-indigo-600 hover:text-indigo-900 ml-4">
                            Privacy Policy
                        </a>
                        <a href="/tos" className="text-indigo-600 hover:text-indigo-900 ml-4">
                            Terms of Service
                        </a>
                    </div>
                </div>
            </div>
        </div>



    );
};

export default NoAccess;
