import React, { useState, useEffect } from 'react';
import { auth } from './config/firebase';
import { User } from "firebase/auth";
import Navbar from './navbar';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { Dialog, Tab, DialogPanel } from '@headlessui/react';
import logo from './assets/_7afa50e7-4fe5-42b5-a782-8293c4c5172e-removebg-preview.png';
import logoText from './assets/logo_text.png'
import logoLato from './assets/logo_lato.png'


const navigation = [
    { name: 'How it works', href: '#' },
    { name: 'FAQ', href: '#' },
    { name: 'Blog', href: '#' },
    { name: 'About us', href: '#' },
];



const TermsOfService = () => {
    const [user, setUser] = useState<User | null>(null);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((authUser) => {
            if (authUser) {
                setUser(authUser);
            } else {
                console.log("User is signed out");
                setUser(null);
            }
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {

        document.title = 'Terms of Service | HinterviewGPT';

    }, []);

    return (
        <div className="" >
           <header className={`top-0 z-50 transition-all duration-300 border-b bg-[#f5f8fa]`}>
                <nav
                    aria-label="Global"
                    className="max-w-7xl mx-auto flex items-center justify-between py-4 px-2 lg:px-4"
                >
                    <div className="flex lg:flex-1">
                        <a href="/" className="-m-1.5 p-1.5">
                            <span className="sr-only">Your Company</span>
                            <img alt="" src={logoLato} className="h-8 w-auto" />
                        </a>
                    </div>
                    <div className="flex lg:hidden">
                        <button
                            type="button"
                            onClick={() => setMobileMenuOpen(true)}
                            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                        >
                            <span className="sr-only">Open main menu</span>
                            <Bars3Icon aria-hidden="true" className="h-6 w-6" />
                        </button>
                    </div>
                    <div className="hidden lg:flex lg:gap-x-12">
                        {navigation.map((item) => (
                            <a
                                key={item.name}
                                href={item.href}
                                className="text-sm font-semibold leading-6 text-gray-900"
                            >
                                {item.name}
                            </a>
                        ))}
                    </div>
                    <div className="hidden lg:flex lg:flex-1 lg:justify-end items-center">
                        {user ? (
                            <a
                                href="/activity"
                                className="text-sm font-semibold leading-6 text-gray-900"
                            >
                                App <span aria-hidden="true">&rarr;</span>
                            </a>
                        ) : (
                            <>
                                <a
                                    href="/auth?type=login"
                                    className="rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    Log In
                                </a>
                                <a
                                    href="/auth?type=register"
                                    className="ml-4 rounded-md border border-indigo-600 px-4 py-2 text-sm font-semibold text-indigo-600 hover:bg-indigo-600 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    Register
                                </a>
                            </>
                        )}
                    </div>
                </nav>

                {/* Mobile Menu */}
                <Dialog open={mobileMenuOpen} onClose={setMobileMenuOpen} className="lg:hidden">
                    <div className="fixed inset-0 z-50" />
                    <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                        <div className="flex items-center justify-between">
                            <a href="/" className="-m-1.5 p-1.5">
                                <span className="sr-only">Your Company</span>
                                <img
                                    alt=""
                                    src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                                    className="h-8 w-auto"
                                />
                            </a>
                            <button
                                type="button"
                                onClick={() => setMobileMenuOpen(false)}
                                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                            >
                                <span className="sr-only">Close menu</span>
                                <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                            </button>
                        </div>
                        <div className="mt-6 flow-root">
                            <div className="-my-6 divide-y divide-gray-500/10">
                                <div className="space-y-2 py-6">
                                    {navigation.map((item) => (
                                        <a
                                            key={item.name}
                                            href={item.href}
                                            className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                        >
                                            {item.name}
                                        </a>
                                    ))}
                                </div>
                                <div className="py-6">
                                    {user ? (
                                        <a
                                            href="/activity"
                                            className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                        >
                                            App
                                        </a>
                                    ) : (
                                        <>
                                            <a
                                                href="/auth?type=login"
                                                className="rounded-md bg-indigo-600 px-4 py-2 text-base font-semibold text-white hover:bg-indigo-500"
                                            >
                                                Log In
                                            </a>
                                            <a
                                                href="/auth?type=register"
                                                className="ml-4 rounded-md border border-indigo-600 px-4 py-2 text-base font-semibold text-indigo-600 hover:bg-indigo-600 hover:text-white"
                                            >
                                                Register
                                            </a>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </DialogPanel>
                </Dialog>
            </header>

            <main className="p-4 lg:px-28 mt-4">
                <h1 className="text-2xl font-bold mb-4">Terms of Service</h1>

                <section className="mb-6">
                    <h2 className="text-xl font-semibold">Introduction</h2>
                    <p>
                        Welcome to HinterviewGPT. By using our services, you agree to these Terms of Service. Please read them carefully.
                    </p>
                </section>

                <section className="mb-6">
                    <h2 className="text-xl font-semibold">Use of Services</h2>
                    <p>
                        HinterviewGPT grants you a limited, non-exclusive, non-transferable, and revocable license to use our services in accordance with these Terms. You agree not to use our services for any unlawful or prohibited activities.
                    </p>
                </section>

                <section className="mb-6">
                    <h2 className="text-xl font-semibold">User Content</h2>
                    <p>
                        By submitting content through HinterviewGPT, including questions, solutions, and other data, you grant us a license to use, modify, and display this content solely for the purpose of providing our services to you. You retain ownership of all your content.
                    </p>
                    <p>
                        You are responsible for ensuring that your content does not violate any laws or infringe on the rights of third parties. We reserve the right to remove any content that violates these terms.
                    </p>
                </section>

                <section className="mb-6">
                    <h2 className="text-xl font-semibold">Account Security</h2>
                    <p>
                        You are responsible for maintaining the security of your account and for all activities that occur under your account. You agree to notify us immediately of any unauthorized use of your account.
                    </p>
                </section>

                <section className="mb-6">
                    <h2 className="text-xl font-semibold">Limitation of Liability</h2>
                    <p>
                        To the fullest extent permitted by law, HinterviewGPT shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from your use of our services.
                    </p>
                </section>

                <section className="mb-6">
                    <h2 className="text-xl font-semibold">Termination</h2>
                    <p>
                        We reserve the right to suspend or terminate your access to our services at any time, with or without notice, for conduct that we believe violates these Terms of Service or is harmful to other users of our services, us, or third parties, or for any other reason.
                    </p>
                </section>

                <section className="mb-6">
                    <h2 className="text-xl font-semibold">Governing Law</h2>
                    <p>
                        These Terms of Service shall be governed by and construed in accordance with the laws of the jurisdiction in which HinterviewGPT operates, without regard to its conflict of law principles.
                    </p>
                </section>

                <section className="mb-6">
                    <h2 className="text-xl font-semibold">Changes to These Terms</h2>
                    <p>
                        We may update these Terms of Service from time to time. If we make material changes, we will provide you with notice through our services or by other means, to give you the opportunity to review the changes before they become effective. By continuing to use our services after the changes come into effect, you agree to be bound by the revised terms.
                    </p>
                </section>

                <section className="mb-6">
                    <h2 className="text-xl font-semibold">Contact Us</h2>
                    <p>
                        If you have any questions or concerns about these Terms of Service, please contact us at support@hinterviewgpt.com.
                    </p>
                </section>
            </main>
            <div className="bg-white border-t border-gray-200 mt-auto">
                <div className="mx-auto max-w-7xl py-3 px-4 sm:px-6 lg:px-8 flex justify-between items-center text-sm">
                    <p className="text-gray-500">
                        © 2024 HinterviewGPT. All rights reserved.
                    </p>
                    <div>
                        <a href="/privacy" className="text-indigo-600 hover:text-indigo-900 ml-4">
                            Privacy Policy
                        </a>
                        <a href="/tos" className="text-indigo-600 hover:text-indigo-900 ml-4">
                            Terms of Service
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TermsOfService;
